<template>
    <v-sheet class="pa-4 root">
        <router-link :to="`/resources/${article.url}`" class="d-block">
            <img :src="article.src" style="object-fit: cover;">
        </router-link>
        <p style="text-align: center; margin-top: 0.8rem;"><b>{{ article.title }}</b></p>
    </v-sheet>
</template>
<script >
export default{
    name:"RecentArticle",
    props:{
        article:Object,
    },
    // data(){
    //     return{
    //         article:{
    //             src: require("@/assets/article-1.png"),
    //             url:"streamlining-private-credit-deal-management",
    //             title:"Streamlining Private Credit Deal Management - Unlocking Efficiency and Empowering Decision"
    //         }  
    //     }
    // }
}
</script>

<style scoped>
img{
    width: 250px;
    height: 150px;
    object-fit: cover;
}
.root{
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px -1px 5px 1px rgba(171,171,171,1);
    -moz-box-shadow: 0px -1px 5px 1px rgba(171,171,171,1);
    box-shadow: 0px -1px 5px 1px rgba(171,171,171,1);
}
</style>
<template>
  <v-container style="padding: 0" fluid>
    <HeadingSeparator
      title="RECENT ARTICLES"
      styles="display: flex; align-items: center; justify-content: center;"
      div="<div></div>"
    >
    </HeadingSeparator>
    <!-- <div style="background-color: #383838; height: 100px; color: white; display: flex; align-items: center; justify-content: center; font-size: xx-large;">RECENT ARTICLES</div> -->
    <div style="margin: auto; margin-bottom: 5rem">
      <!-- <div class="text-h3 text-center mb-10">Recent Article</div> -->
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <v-col
          style="display: flex; justify-content: space-around; flex-wrap: wrap"
        >
          <Card
            v-for="(article, i) in articles"
            :key="i"
            :article="article"
            style="margin: 1rem"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-col>
            <Card
              v-for="(article, i) in articles"
              :key="i"
              :article="article"
              style="margin: 1rem"
            />
          </v-col>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Card from "@/views/home/Card.vue";
import HeadingSeparator from "@/views/sections/HeadingSeparator.vue";
export default {
  name: "RecentArticle",
  components: {
    Card,
    HeadingSeparator,
  },

  data() {
    return {
      articles: [
        {
          src: require("@/assets/article-1.png"),
          url: "streamlining-private-credit-deal-management-with-mavenx",
          title:
            "Streamlining Private Credit Deal Management - Unlocking Efficiency and Empowering Decision with MavenX",
        },
        {
          src: require("@/assets/blog2.png"),
          url: "offshore-development-centers",
          title:
            "Offshore Development Centers Benefits, Management, and Partner Selection",
        },
        {
          src: require("@/assets/blog3_1.png"),
          url: "how-spreadswift-can-help-financial-due-diligence-for-asset-managers",
          title:
            "How SpreadSwift can help quicken Financial Due Diligence process for Asset Managers",
        },
        // {
        //     src: require("@/assets/article-1.png"),
        //     url:"streamlining-private-credit-deal-management",
        //     title:"Streamlining Private Credit Deal Management - Unlocking Efficiency and Empowering Decision"
        // },
      ],
    };
  },
};
</script>